<template>
  <div class="container-fluid">
    <!-- file pond -->
    <card class="w-100 p-5 my-5">
      <!-- title -->
      <h5 class="my-3">وارد کردن گروهی آدرس ها</h5>
      <!-- file pond -->
      <div class="mt-5 w-75 file-pond">
        <FilePond v-on:processfile="fileProcess()" v-model="fileImport" accepted-file-types="application/vnd.ms-excel" :server="server" name="file" ref="pond" label-idle="... فایل را اینجا رها کنید" v-bind:allow-multiple="false"> </FilePond>
      </div>
      <span>فرمت ورودی اطلاعات باید CSV باشد</span>
      <!-- btn submit -->
      <div>
        <base-button class="my-4" type="primary">افزودن گروهی</base-button>
      </div>
    </card>
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType)

let api = 'http://192.168.1.131:8080/api/management/address'
export default {
  name: 'Import',
  data() {
    return {
      fileImport: null,
      /**filepond server */
      server: {
        process: api,
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': '321'
        },
        revert: null
      }
    }
  },
  methods: {
    fileProcess() {}
  },
  components: {
    FilePond
  },
  mounted() {
    this.$rtl.enableRTL()
  }
}
</script>

<style lang="scss">
/* Import FilePond styles */
@import '~filepond/dist/filepond.min.css';

.filepond--panel-root {
  background: #cbccce !important;
}
.filepond--drop-label label {
  color: black !important;
  cursor: pointer;
}
.file-pond * {
  cursor: pointer;
}
@media (max-width: 991.98px) {
  .file-pond {
    width: 100% !important;
  }
}
</style>
